import React from "react"
import Helmet from "react-helmet"
import Layout from "../../../components/layout"
import { Container, Row, Col } from "react-bootstrap"
import JumboPhoto from "../../../components/jumbophoto"

export default () => <Layout>
	<Helmet title="Bankruptcy &amp; Divorce | Gina Mcdonald &amp; Associates, L.L.C." defer={false} />

	<JumboPhoto/>

	<Container>
		<Row>
			<Col>
				<h1 className="text-primary">Bankruptcy and Divorce</h1>
				<hr />
				<p>At the Gina H. McDonald and Associates, LLC, we understand divorce. Both Ms. McDonald and Ms. Bellew have represented clients in divorce proceedings. We understand how bankruptcy and divorce can often times interact.</p>
				<h2>Filing for Bankruptcy after Your Divorce</h2>
				<p>Both divorce and bankruptcy are stressful enough on their own. The added pressure of dealing with both issues at the same time might feel like too much. A lawyer well versed in both areas can help you work out the two issues simultaneously. At Gina H. McDonald &amp; Associates in Birmingham, Alabama, we have a comprehensive bankruptcy practice, as well as a comprehensive family law practice. We can help you figure out a plan that works for you.</p>
				<p className="lead">If you have questions about debt relief, please contact Gina H. McDonald &amp; Associates at (205) 982-3325.</p>
				<p>We understand how difficult it is to navigate the complexities of both a divorce and bankruptcy at the same time. When you step into our offices, you will meet directly with your attorney. We will listen to your concerns and work to tailor a plan that works for you and your family. With the appropriate legal counsel, you can take care of your bankruptcy issues and your divorce at the same time.</p>
				<p>We are familiar with the issues of property division and other issues that arise from divorces and are related to bankruptcy. If you are filing for bankruptcy after your divorce, it can be complicated when you are discharging joint debt. We are familiar with bankruptcy and domestic relations, and can advise you how to best proceed. Every case is different, so we will take the time to listen to your unique concerns.</p>

				<h2>Domestic Support Obligations</h2>
				<p>If you are in debt, you are still required to take care of your domestic support obligations, such as child support and alimony. These debts are generally non-dischargeable and cannot be eliminated by filing for bankruptcy. However, you have options, and by filing for bankruptcy you can free up some debt, which will allow you to fulfill your domestic support obligations.</p>

				<h2>Contact Us</h2>
				<p>Going through bankruptcy is never easy. It is even more difficult when you are going through a divorce at the same time. At Gina H. McDonald &amp; Associates, we help clients with their family law and bankruptcy issues. We understand how the two practices work together, and we understand your concerns during this difficult time. Contact us today for an initial consultation.</p>
				<p className="lead">We are a debt relief agency. We help people file for debt relief under the Bankruptcy Code.</p>
				<p>The following language is required pursuant to Rule 7.2, Alabama Rules of Professional Conduct. No representation is made that the quality of legal services to be performed is greater than the quality of legal services performed by other lawyers. This web site is designed for general information only. The information presented at this site should not be construed to be formal legal advice nor the formation of a lawyer/client relationship.</p>
			</Col>
		</Row>
	</Container>
</Layout>